<template>
  <v-card>
    <div class="px-4 py-2">
      <div v-if="comment.createdBy != userId">
        {{
          comment.author.firstname + ' ' +
          comment.author.middlename + ' ' +
          comment.author.lastname
        }}
      </div>
      <div v-else class="text-right">Я</div>
      <div :class="[{ 'text-right': comment.createdBy == userId}, 'text--disabled']">
        {{secToDatetime(comment.createdAt)}}
      </div>
    </div>
    <v-divider></v-divider>
    <v-card-text style="white-space: pre;">
      {{ comment.message }}
    </v-card-text>
  </v-card>
</template>

<script>
import { secToDatetime } from '@/libs/time.js'
import { mapState } from 'vuex'

export default {
  props: ['comment'],
  data: () => ({ secToDatetime }),
  computed: {
    ...mapState('user', { userId: state => state.profile.userId }),
  },
}
</script>
